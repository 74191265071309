import React from 'react';

export default function VideoLogo() {
  return (
    <svg
      id="Capa_1"
      enable-background="new 0 0 512 512"
      height="100"
      viewBox="0 0 512 512"
      width="100"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path d="m296 256c0-22.056-17.944-40-40-40s-40 17.944-40 40 17.944 40 40 40 40-17.944 40-40zm-40 20c-11.028 0-20-8.972-20-20s8.972-20 20-20 20 8.972 20 20-8.972 20-20 20z" />
        <path d="m70 476h141c5.522 0 10-4.477 10-10s-4.478-10-10-10h-141c-24.146 0-44.35-17.206-48.995-40.01h469.99c-4.645 22.804-24.849 40.01-48.995 40.01h-141c-5.522 0-10 4.477-10 10s4.478 10 10 10h141c38.598 0 70-31.402 70-70 0-5.523-4.478-10-10-10h-35v-260c0-5.523-4.478-10-10-10h-71v-80c0-3.688-2.03-7.077-5.281-8.817-3.252-1.74-7.199-1.549-10.266.497l-74.453 49.635v-41.315c0-5.523-4.478-10-10-10h-150c-5.522 0-10 4.477-10 10v80h-71c-5.522 0-10 4.477-10 10v260h-35c-5.522 0-10 4.477-10 10 0 38.598 31.402 70 70 70zm236-80h-100v-30c0-27.57 22.43-50 50-50s50 22.43 50 50zm60-331.315v82.63l-61.973-41.315zm-220-8.685h130v100h-130zm-81 90h61v20c0 5.523 4.478 10 10 10h150c5.522 0 10-4.477 10-10v-41.315l74.453 49.635c3.073 2.049 7.019 2.234 10.266.497 3.251-1.74 5.281-5.129 5.281-8.817v-20h61v250h-121v-30c0-38.598-31.402-70-70-70s-70 31.402-70 70v30h-121z" />
        <circle cx="256" cy="466" r="10" />
      </g>
    </svg>
  );
}
